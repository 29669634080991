<template>
	<div class="analytics">
		<div class="title">
			<h4>{{ translate('quiz.analytics') }}</h4>
		</div>

		<div v-if="analytics" class="quick-stats">
			<div class="stat">
				<div>
					<p class="label">{{ translate('players') }}</p>
					<p class="number">{{ analytics.length }}</p>
				</div>
				<Icon name="people" class="purple" width="34" height="34" />
			</div>

			<div class="stat">
				<div>
					<p class="label">{{ translate('averageResult') }}</p>
					<p class="number">{{ averageResult }}</p>
				</div>
				<Icon name="checkmark-circle-2" class="green" width="34" height="34" />
			</div>

			<div class="stat">
				<div>
					<p class="label">{{ translate('averageTime') }}</p>
					<p class="number">{{ formatTime(averageTime) }}</p>
				</div>
				<Icon name="clock-outline" class="gray" width="34" height="34" />
			</div>
		</div>

		<div class="leaderboard">
			<p class="label">{{ translate('leaderboard') }}</p>
			<div v-for="(player, index) in leaderboard" :key="index" class="player">
				<span>{{ index + 1 }}.</span>
				<User :id="player.user_id" :clickable="true" />
				<span v-if="adminMode" class="player-id">{{ player.id }}</span>
				<Score v-tippy :override="player.score" :content="translate('score')" />
			</div>
			<p class="show-more" @click="showAllPlayers = !showAllPlayers">
				<span>{{ showAllPlayers ? translate('showLess') : translate('showMore') }}</span>
				<Icon name="arrowhead-down" width="16" height="14" :class="{ flip: showAllPlayers }" />
			</p>
		</div>

		<div v-if="questions && analytics" class="results">
			<p class="label">{{ translate('result') }}</p>
			<div v-for="(question, index) in questions" :key="index" class="question">
				<p class="number">{{ index + 1 }}.</p>
				<p class="title">{{ question.question }}</p>
				<p class="result">
					<span v-tippy :content="translate('playersWithCorrectAnswer')"
						>{{ question.correct_guesses }}/{{ analytics.length }}</span
					>
					<Icon name="people" class="gray" width="20" />
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { db } from '@/lib/supabase'
import { average, formatTime, handleError } from '@/utils/utils'

import User from '@/components/User.vue'
import Score from '@/components/Score.vue'
import Icon from './Icon.vue'

export default {
	components: {
		User,
		Score,
		Icon,
	},

	props: {
		quiz: {
			type: Object,
			required: true,
		},

		adminMode: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	data() {
		return {
			analytics: [],
			questions: [],
			showAllPlayers: false,
		}
	},

	computed: {
		averageResult() {
			let correctGuesses = []

			for (let user of this.analytics) {
				correctGuesses.push(user.correct_guesses)
			}

			return `${Math.floor(average(correctGuesses))}/${this.questions.length}`
		},

		averageTime() {
			let times = []

			for (let user of this.analytics) {
				times.push(Date.parse(user.completed_at) - Date.parse(user.started_at))
			}

			return Math.floor(average(times))
		},

		leaderboard() {
			let users = [...this.analytics]
			users = users.sort((a, b) => b.score - a.score)
			return this.showAllPlayers ? users : users.slice(0, 3)
		},
	},

	async beforeMount() {
		this.analytics = await this.getAnalytics()
		this.questions = await this.getQuestionsAnalytics()
	},

	methods: {
		async getAnalytics() {
			const { data, error } = await db.rpc('get_quiz_analytics', { quiz_id_param: this.quiz.id })
			if (error) handleError(error)
			else return data
		},

		async getQuestionsAnalytics() {
			const { data, error } = await db.rpc('get_quiz_analytics_questions', {
				quiz_id_param: this.quiz.id,
			})
			if (error) handleError(error)
			else return data
		},

		formatTime,
	},
}
</script>

<style lang="scss" scoped>
.analytics {
	cursor: default;

	.label {
		background: none;
		border-radius: 0;
		color: darken(white, 50%);
		padding: 0;
	}

	> .title {
		margin-bottom: 15px;
	}

	.quick-stats {
		align-items: center;
		display: flex;

		@include mobile {
			display: block;

			.stat + .stat {
				margin-left: 0 !important;
				margin-top: 20px;
			}
		}

		.stat {
			align-items: center;
			background: darken(white, 5%);
			border-radius: $border-radius;
			display: flex;
			flex: 1;
			justify-content: space-between;
			padding: 15px 20px 15px;

			+ .stat {
				margin-left: 20px;
			}

			.number {
				font-size: 24px;
				font-weight: 500;
			}
		}
	}

	.leaderboard {
		align-items: center;
		background: darken(white, 5%);
		border-radius: $border-radius;
		display: flex;
		flex-direction: column;
		font-size: 16px;
		padding: 15px 20px;
		margin-top: 20px;

		.label {
			align-self: flex-start;
			margin-bottom: 15px;
		}

		.player {
			align-items: center;
			display: flex;
			width: 100%;

			+ .player {
				margin-top: 8px;
			}

			span {
				font-weight: 600;
				width: 25px;
			}

			.player-id {
				color: $primary-color;
				cursor: text;
				font-size: 14px;
				font-weight: 500;
				margin-left: 10px;

				&::before,
				&::after {
					color: black;
					font-size: 16px;
				}

				&::before {
					content: '(';
				}

				&::after {
					content: ')';
				}
			}

			::v-deep .score {
				margin-left: auto;
			}
		}

		.show-more {
			align-items: center;
			cursor: pointer;
			display: inline-flex;
			font-size: 12px;
			font-weight: 500;
			margin-top: 10px;
			opacity: 0.4;
			position: relative;
			top: 5px;
			transition: 0.25s ease;

			&:hover {
				opacity: 0.6;
			}

			::v-deep .icon {
				i {
					margin-left: 5px;
					position: relative;
					top: 2px;
					transition: 0.25s ease;

					&.flip {
						transform: scaleY(-1);
						top: -2px;
					}
				}
			}
		}
	}

	.results {
		background: darken(white, 5%);
		border-radius: $border-radius;
		padding: 15px 20px;
		margin-top: 20px;

		.label {
			margin-bottom: 10px;
		}

		.question {
			align-items: flex-start;
			display: flex;

			+ .question {
				margin-top: 8px;
			}

			.number {
				font-weight: 600;
				min-width: 30px;
			}

			.title {
				margin-right: 15px;
			}

			.result {
				align-items: flex-start;
				display: flex;
				margin-left: auto;

				span {
					font-weight: 600;
				}

				::v-deep .icon {
					i {
						margin-left: 6px;
						position: relative;
						top: -2px;
					}
				}
			}
		}
	}
}
</style>
