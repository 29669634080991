<template>
	<div :class="['upload-input', { uploading }]">
		<label :for="id" :class="classes">
			<Icon :name="icon" :fill="iconColor" :width="iconSize" />
			<span>{{ text }}</span>
			<Loader
				v-if="uploading"
				:size="16"
				:border-width="2"
				border-color="white"
				position="absolute"
				:center="true" />
		</label>
		<input :id="id" type="file" :accept="accepts" @change="upload($event)" />
	</div>
</template>

<script>
import { upload } from '@/utils/upload'
import { handleError } from '@/utils/utils'
import Loader from '@/components/Loader.vue'
import Icon from './Icon.vue'

export default {
	components: {
		Loader,
		Icon,
	},

	props: {
		bucket: {
			type: String,
			required: true,
		},

		id: {
			type: String,
			default: 'file',
		},

		model: null,

		refId: {
			type: String,
		},

		classes: {
			type: String,
		},

		icon: {
			type: String,
		},

		iconColor: {
			type: String,
			default: 'white',
		},

		iconSize: {
			type: String,
			default: '14',
		},

		text: {
			type: String,
		},

		accepts: {
			type: String,
		},
	},

	data() {
		return {
			uploading: false,
			completed: false,
		}
	},

	methods: {
		async upload(e) {
			this.uploading = true
			const file = e.target.files[0]

			const { publicURL, error } = await upload(file, this.bucket)

			if (error) {
				handleError(error)
			} else {
				this.$emit('uploaded', publicURL, this.model, this.refId)
				this.uploading = false
				this.completed = true

				setTimeout(() => {
					this.completed = false
				}, 1500)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.upload-input {
	position: relative;

	&.uploading {
		::v-deep .icon i,
		span {
			opacity: 0;
		}

		label {
			pointer-events: none;
		}
	}

	label {
		@extend button;
		left: 0;
		margin-bottom: 0;
	}

	input {
		left: -9999px;
		position: fixed;
	}

	::v-deep.loader {
		left: 50%;
		position: absolute;
		transform: translateX(-100%);
	}
}
</style>
