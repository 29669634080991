<template>
	<div class="preview">
		<div v-if="question" :class="['question', question.media_type]">
			<div v-if="question.media_type === 'image'" class="image-container">
				<ImageComponent :src="question.media_url" :overlay="true" />
			</div>

			<div class="text-container">
				<h3>{{ question.question }}</h3>

				<div v-if="question.media_type === 'audio'" class="audio-container">
					<Audio :url="question.media_url" />
				</div>

				<div v-if="question.media_type === 'video'" class="video-container">
					<Video :url="question.media_url" />
				</div>

				<div class="answers">
					<div v-for="(answer, i) in question.options" :key="i" class="radio-input">
						<input
							:id="`question-answer-${i}`"
							:key="answer"
							type="radio"
							:name="`question`"
							:value="answer" />
						<label :for="`question-answer-${i}`">{{ answer }}</label>
					</div>
				</div>
			</div>
		</div>

		<button v-if="question" class="icon-left" @click="close()">
			<Icon name="close" width="20" fill="white" />{{ translate('close') }}
		</button>
	</div>
</template>

<script>
import Audio from '@/components/mediaTypes/Audio.vue'
import Video from '@/components/mediaTypes/Video.vue'
import ImageComponent from '@/components/ImageComponent.vue'
import Icon from './Icon.vue'

export default {
	components: {
		Audio,
		ImageComponent,
		Video,
		Icon,
	},

	props: {
		question: {
			type: Object,
			default: function () {
				return {
					question: '',
					media_type: 'text',
					media_url: '',
					correctAnswer: '',
					answers: ['', '', ''],
				}
			},
		},
	},

	methods: {
		close() {
			this.$refs.plyr_audio?.player.stop()
			document.body.classList.remove('no-scroll')
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss" scoped>
$question-max-width: 1000px;

.preview {
	background: rgba(white, 0.75);
	height: 100%;
	left: 0;
	overflow-y: auto;
	padding-bottom: 75px;
	position: fixed;
	text-align: center;
	top: 0;
	width: 100%;
}

.question {
	display: flex;
	flex-direction: column;
	margin: 100px auto 0;
	max-width: $question-max-width;
	text-align: center;
	width: 100%;

	&.image {
		flex-direction: row;

		h3 {
			text-align: left;
		}
	}

	.image-container {
		padding-right: 50px;

		@include mobile {
			margin-bottom: 10px;
			padding-right: 0;
		}

		::v-deep.image-component {
			align-items: center;
			display: flex;
			justify-content: center;
			max-height: 50vh;

			> img {
				$size: 500px;

				@include lowHeight {
					$size: 250px;
				}

				border-radius: $border-radius * 4;
				display: block;
				height: 100%;
				min-width: $size;
				object-fit: cover;
				width: $size;

				@include mobile {
					height: 150px;
					width: auto;
				}
			}
		}
	}

	.audio-container,
	.video-container {
		margin-top: 25px;
	}

	.text-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
		padding-top: 10px;
	}

	h3 {
		display: inline-block;
		font-size: 26px;
		line-height: 1.5;

		@include laptop {
			font-size: 22px;
		}

		@include lowHeight {
			font-size: 20px;
		}

		@include mobile {
			font-size: 18px;
		}
	}

	.answers {
		margin: 35px auto 0;
		width: 100%;

		@include mobile {
			display: block;
			justify-self: flex-end;
			margin-top: 20px;
		}
	}

	.radio-input {
		width: 100%;

		+ .radio-input {
			margin-top: 15px;
		}

		label {
			background: darken(white, 20%);
			border-radius: $border-radius;
			cursor: pointer;
			color: $font-color;
			font-size: 18px;
			left: 0;
			line-height: 1.5;
			padding: 15px;
			pointer-events: none;
			transition: $transition;

			@include mobile {
				font-size: 14px;
				padding: 10px;
			}

			@include landscape {
				font-size: 14px;
				padding: 10px;
			}

			&:hover {
				background: darken(white, 25%);
			}
		}

		input[type='radio'] {
			position: absolute;
			left: -9999px;

			&.correct + label {
				background: $green;
				color: $font-color-contrast;
			}

			&.incorrect + label {
				background: $red;
				color: $font-color-contrast;
			}
		}
	}
}

button {
	margin-top: 100px;

	@include laptop {
		margin-top: 50px;
	}

	@include mobile {
		margin-top: 30px;
	}

	@include landscape {
		margin-top: 30px;
	}
}
</style>
