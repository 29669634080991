<template>
	<Transition name="fade" appear>
		<div class="backdrop" @click.self="close()">
			<Transition name="fade-slide-up" appear>
				<div class="cover-image-selector">
					<div class="header">
						<div class="input-container form-input">
							<Icon name="search" width="24" />
							<input
								ref="searchInput"
								v-model="query"
								type="text"
								:placeholder="translate('search')"
								@input="search()" />
						</div>

						<transition name="fade">
							<Loader v-if="searching" :size="20" :border-width="2" position="absolute" />
						</transition>

						<button class="icon" @click="close()">
							<Icon name="close" fill="gray" width="30" />
						</button>
					</div>

					<div class="body">
						<div v-if="images.length > 0" class="images">
							<div
								v-for="image in images"
								:key="image.id"
								:class="['image', { selected: selected && selected.id === image.id }]"
								:style="{ opacity: selected && selected.id !== image.id ? 0.5 : 1 }"
								@click="select(image.id)">
								<LazyImage
									:height="118"
									:width="222"
									:lazy-src="image.src.large"
									:alt="image.alt" />
							</div>
						</div>

						<transition name="fade">
							<div v-if="emptyResult" class="alert text-center full-width">
								<p v-html="translate('imageSelector.noResult', null, query)"></p>
							</div>
						</transition>
					</div>

					<div class="footer">
						<p
							class="copyright-notice"
							v-html="translate('pexels.copyright', null, query || '')"></p>
						<button class="secondary" @click="close()">
							<span>{{ translate('cancel') }}</span>
						</button>
						<button
							:class="['small', 'icon-left', { disabled: !selected }]"
							:disabled="!selected"
							@click="choose()">
							<Icon name="image" fill="white" width="18" />
							<span>{{ translate('select') }}</span>
						</button>
					</div>
				</div>
			</Transition>
		</div>
	</Transition>
</template>

<script>
import { pexels } from '@/lib/pexels'
import { debounce } from 'debounce'
import LazyImage from '@/components/LazyImage.vue'
import Loader from '@/components/Loader.vue'
import Icon from './Icon.vue'

export default {
	components: {
		LazyImage,
		Loader,
		Icon,
	},

	props: {
		model: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			images: [],
			query: null,
			selected: null,
			searching: false,
			emptyResult: false,
		}
	},

	async beforeMount() {
		await this.getCuratedImages()
	},

	mounted() {
		this.$refs.searchInput.focus()
	},

	methods: {
		async getImages(query) {
			if (query.length === 0) return
			await pexels.photos
				.search({
					query,
					orientation: 'landscape',
					locale: 'sv-SE',
					per_page: 16,
				})
				.then((photos) => {
					this.images = photos.photos
					this.searching = false
					if (this.images.length === 0) this.emptyResult = true
				})
		},

		async getCuratedImages() {
			await pexels.photos
				.curated({
					per_page: 16,
				})
				.then((photos) => (this.images = photos.photos))
		},

		search() {
			if (this.query.length === 0) return
			this.emptyResult = false
			this.searching = true
			this.debouncedSearch()
		},

		select(id) {
			if (this.selected && this.selected.id === id) {
				this.selected = null
				return
			}

			this.selected = this.images.find((image) => image.id === id)
		},

		choose() {
			this.$emit('close', { model: this.model, image: this.selected })
		},

		close() {
			this.$emit('close', { model: this.model, close: true })
		},

		debouncedSearch: debounce(async function () {
			await this.getImages(this.query)
		}, 500),
	},
}
</script>

<style lang="scss" scoped>
.backdrop {
	background: rgba(darken(white, 10%), 0.75);
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	width: 100%;
	z-index: 10;
}

.cover-image-selector {
	background: white;
	border: 2px solid darken(white, 5%);
	border-radius: $border-radius;
	box-shadow: 0px 2px 3.3px rgba(0, 0, 0, 0.028), 0px 6.7px 11.2px rgba(0, 0, 0, 0.042),
		0px 30px 50px rgba(0, 0, 0, 0.07);
	margin: 12vh auto 0;
	max-width: 960px;
	width: 95%;

	.header {
		align-items: center;
		border-bottom: 2px solid darken(white, 5%);
		display: flex;
		padding: 15px;
		position: relative;

		::v-deep.loader {
			right: 70px;
			top: 24px;
			z-index: 1;
		}

		.input-container.form-input {
			width: 100%;

			input {
				border: none;
				font-size: 18px;
				padding-left: 40px;
			}

			::v-deep .icon i {
				left: 5px;
			}
		}

		button {
			margin-left: 15px;
			opacity: 0.75;
			padding: 5px;
			transition: $transition;

			&:hover {
				opacity: 1;
			}
		}
	}

	.body {
		padding: 10px;

		.images {
			display: inline-flex;
			flex-wrap: wrap;
			width: 100%;

			.image {
				border: 6px solid transparent;
				border-radius: $border-radius;
				cursor: pointer;
				height: 130px;
				overflow: hidden;
				transition: $transition;
				width: 25%;

				&:hover {
					position: relative;
					transform: translateY(-1%);
				}

				img {
					background: darken(white, 5%);
					border-radius: $border-radius;
					height: 100%;
					object-fit: cover;
					width: 100%;
				}
			}
		}
	}

	.footer {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 15px;

		p {
			color: gray;
			margin-left: 10px;
			margin-right: auto;
		}

		button + button {
			margin-left: 15px;
		}
	}
}
</style>
