<template>
	<div class="video-thumbnail">
		<div class="overlay" @click="expanded = true">
			<div class="icon">
				<Icon name="arrow-right" fill="white" />
			</div>
		</div>

		<div class="video-wrapper">
			<vue-plyr ref="plyr_video">
				<div class="plyr__video-embed">
					<iframe :src="src"></iframe>
				</div>
			</vue-plyr>
		</div>

		<transition name="fade">
			<Lightbox v-if="expanded" type="video" :src="src" @collapse="expanded = false" />
		</transition>
	</div>
</template>

<script>
import Lightbox from '@/components/Lightbox.vue'
import Icon from './Icon.vue'

export default {
	components: {
		Lightbox,
		Icon,
	},

	props: {
		src: { type: String, required: true },
	},

	data() {
		return {
			expanded: false,
		}
	},
}
</script>

<style lang="scss" scoped>
.video-thumbnail {
	position: relative;

	&:hover {
		.overlay {
			background: rgba(black, 0.25);
		}
	}

	.overlay {
		align-items: center;
		cursor: pointer;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		transition: $transition;
		width: 100%;
		z-index: 2;

		.icon {
			align-items: center;
			background: $primary-color;
			border-radius: 100px;
			display: flex;
			justify-content: center;
			padding: 6px;
		}

		::v-deep .icon i {
			transform: scale(125%);
		}
	}

	.video-wrapper {
		::v-deep.plyr {
			pointer-events: none;
		}

		::v-deep.plyr__controls,
		::v-deep.plyr__control {
			display: none;
		}
	}
}
</style>
