import { auth, storage } from '../lib/supabase'
import { nanoid } from 'nanoid'

const upload = async (file, bucket) => {
	let [name, extension] = file.name.split('.')
	let filename = `${name}-${nanoid(8)}.${extension}`
	let location = `${auth.user().id}/${filename}`

	const { error } = await storage.from(bucket).upload(location, file)

	if (error) return error
	else {
		const { publicURL, error } = await getMediaUrl(bucket, location)
		return { publicURL, error }
	}
}

const getMediaUrl = async (bucket, location) => {
	const { publicURL, error } = await storage.from(bucket).getPublicUrl(location)
	return { publicURL, error }
}

export { upload }
