<template>
	<div style="height: 100%">
		<div v-if="loading" class="loader-container fullscreen">
			<Loader :size="50" :border-width="6" />
		</div>

		<div
			v-if="!loading && quiz && !notCreatedByMe && !quizDoesNotExist"
			class="builder"
			:style="{
				filter: preview ? 'blur(7px)' : null,
			}">
			<Transition name="fade" appear>
				<div
					v-if="adminMode"
					class="alert dev purple full-width text-center"
					style="margin-bottom: 50px">
					<Icon name="alert-triangle" />
					<p>
						Admin Mode is enabled, be careful! Quiz ID:
						<b>{{ id }}</b>
					</p>
				</div>
			</Transition>

			<TransitionGroup name="fade-slide-up" appear>
				<div :key="1" class="row auto">
					<!-- Quiz Title -->
					<div
						id="quiz-title"
						class="form-input column"
						:class="{
							error: error.title,
						}"
						:data-error="error.title">
						<div class="label">
							<label for="title">{{ translate('quiz.title') }}</label>
							<p
								v-tippy
								:class="[
									'char-count',
									{
										'text-red': (quiz.title ? quiz.title.length : 0) > characterLimits.quizTitle,
									},
								]"
								:content="translate('quiz.maxCharacterLength')"
								tabindex="-1">
								{{ quiz.title ? quiz.title.length : 0 }}/{{ characterLimits.quizTitle }}
							</p>
						</div>
						<input
							id="title"
							v-model="quiz.title"
							type="text"
							:class="[`state-${state.title}`]"
							:data-maxlength="characterLimits.quizTitle"
							:placeholder="translate('quiz.title.placeholder')"
							@input="fieldUpdate($event)" />
						<InputState :state="state.title" :show="!!state.title" />
					</div>

					<!-- Quiz Qid -->
					<div
						id="quiz-qid"
						class="form-input column"
						:class="{
							error: error.qid,
						}"
						:data-error="error.qid">
						<div class="label">
							<label for="qid">{{ translate('quiz.id') }}</label>
							<p class="notice">
								{{ translate('mustBeUnique') }}
							</p>
							<p
								v-tippy
								:class="[
									'char-count',
									{
										'text-red': (quiz.qid ? quiz.qid.length : 0) > characterLimits.quizQid,
									},
								]"
								:content="translate('quiz.maxCharacterLength')"
								tabindex="-1">
								{{ quiz.qid ? quiz.qid.length : 0 }}/{{ characterLimits.quizQid }}
							</p>
						</div>
						<div class="input-container">
							<input
								id="qid"
								v-model="quiz.qid"
								type="text"
								:class="[`state-${state.qid}`, 'prepended']"
								:data-maxlength="characterLimits.quizQid"
								:placeholder="translate('quiz.id.placeholder')"
								@input="
									error.qid = null
									state.qid = 'loading'
									debounceQid($event)
								" />
							<div class="prepend">
								{{ baseUrl }}
							</div>
							<InputState
								:state="state.qid"
								:show="!!state.qid"
								:style="{
									right: '90px',
								}" />
							<button class="small icon-left" @click="copy($event, baseUrl + quiz.qid)">
								<Icon name="clipboard" fill="white" width="14" /><span>{{
									translate('copy')
								}}</span>
							</button>
						</div>
					</div>

					<!-- Quiz Privacy -->
					<div class="form-input column">
						<div class="label">
							<label for="public">{{ translate('quiz.privacy') }}</label>
							<p class="notice">
								<Icon
									name="question-mark-circle"
									width="17"
									fill="gray"
									:vtippy="true"
									:content="translate('quiz.privacy.tooltip')" />
							</p>
						</div>
						<div class="public-toggle">
							<input id="public" v-model="quiz.public" type="checkbox" @change="fieldUpdate()" />
							<label for="public">
								<span>{{ translate('quiz.privacy.private') }}</span>
								<span>{{ translate('quiz.privacy.public') }}</span>
							</label>
						</div>
					</div>
				</div>

				<div :key="2" class="row auto" style="transition-delay: 0.1s">
					<!-- Quiz Description -->
					<div
						class="form-input column"
						:class="{
							error: error.description,
						}"
						:data-error="error.description">
						<div class="label">
							<label for="description">{{ translate('quiz.description') }}</label>
							<p
								v-tippy
								:class="[
									'char-count',
									{
										'text-red':
											(quiz.description ? quiz.description.length : 0) >
											characterLimits.quizDescription,
									},
								]"
								:content="translate('quiz.maxCharacterLength')"
								tabindex="-1">
								{{ quiz.description ? quiz.description.length : 0 }}/{{
									characterLimits.quizDescription
								}}
							</p>
						</div>
						<textarea
							id="description"
							v-model="quiz.description"
							rows="3"
							:class="[`state-${state.description}`]"
							:data-maxlength="characterLimits.quizDescription"
							:placeholder="translate('quiz.description.placeholder')"
							:style="{
								maxHeight: '120px',
							}"
							@input="fieldUpdate($event)"></textarea>
						<InputState :state="state.description" :show="!!state.description" />
					</div>
				</div>

				<div :key="3" class="row auto" style="transition-delay: 0.2s">
					<!-- Quiz Cover URL -->
					<div
						class="form-input column"
						:class="{
							error: error.cover_url,
						}"
						:data-error="error.cover_url">
						<div class="label">
							<label for="cover_url">{{ translate('quiz.cover_url') }}</label>
							<p class="notice">
								{{ translate('quiz.imageUrl.notice') }}
							</p>
						</div>
						<div class="input-container">
							<input
								id="cover_url"
								v-model="quiz.cover_url"
								type="text"
								:class="[`state-${state.cover_url}`]"
								:placeholder="translate('quiz.cover_url.placeholder')"
								:style="{
									paddingRight: '240px',
								}"
								@input="fieldUpdate($event)" />
							<InputState
								:state="state.cover_url"
								:show="!!state.cover_url"
								:style="{
									right: '230px',
								}" />
							<div class="buttons">
								<button
									v-tippy
									class="small icon"
									:content="translate('quiz.cover_url.preview')"
									@click="previewStart()">
									<Icon
										name="eye"
										fill="gray"
										width="20"
										:style="{
											opacity: 0.7,
										}" />
								</button>
								<button
									class="small icon-left"
									@click="
										selectImage({
											model: 'cover_url',
											id: 'cover_url',
										})
									">
									<Icon name="image" fill="white" width="14" />
									<span>{{ translate('quiz.chooseImage') }}</span>
								</button>
								<UploadInput
									id="cover-image-upload"
									ref-id="cover_url"
									model="cover_url"
									bucket="quiz-images"
									classes="small icon-left"
									icon="cloud-upload-outline"
									:text="translate('quiz.uploadImage')"
									@uploaded="handleUpload" />
							</div>
						</div>
					</div>
				</div>
			</TransitionGroup>

			<Transition name="fade-slide-up" appear>
				<Analytics
					v-if="readonly || adminMode"
					:quiz="quiz"
					:admin-mode="adminMode"
					style="transition-delay: 0.3s" />
			</Transition>

			<div class="questions-wrapper">
				<Transition name="fade-slide-up" appear>
					<div class="title" style="transition-delay: 0.4s">
						<h4>
							{{ translate('quiz.questions') }}
						</h4>
						<p v-if="!readonly">
							{{ questions.length }}
							/
							{{ questionsLimit }}
						</p>
						<p v-if="readonly" class="hide-questions" @click="questionsHidden = !questionsHidden">
							<span>{{
								questionsHidden ? translate('quiz.showQuestions') : translate('quiz.hideQuestions')
							}}</span>
							<Icon
								name="arrowhead-up"
								width="20"
								:class="{
									hidden: questionsHidden,
								}" />
						</p>
					</div>
				</Transition>

				<Transition name="fade-slide-up" appear>
					<div v-if="readonly" class="alert blue full-width" style="transition-delay: 0.5s">
						<Icon name="info" />
						<p>
							{{ translate('quiz.notice.questionsLockedOncePlayed') }}
						</p>
					</div>
				</Transition>

				<div
					:class="[
						{
							hidden: questionsHidden,
						},
						'questions',
					]">
					<TransitionGroup @before-enter="beforeEnter" @enter="enter" @afterEnter="afterEnter">
						<div
							v-for="(question, index) in questions"
							:key="index"
							class="question"
							:data-index="index">
							<div class="title">
								<h4>#{{ index + 1 }}</h4>

								<div v-if="!readonly" class="mediaType radio">
									<input
										:id="`question${index}mediaTypeText`"
										v-model="question.media_type"
										type="radio"
										:name="`question${index}mediaType`"
										value="text"
										@change="
											fieldUpdate()
											question.media_url = ''
										" />
									<label :for="`question${index}mediaTypeText`">{{
										translate('mediaType.text')
									}}</label>

									<input
										:id="`question${index}mediaTypeImage`"
										v-model="question.media_type"
										type="radio"
										:name="`question${index}mediaType`"
										value="image"
										@change="
											fieldUpdate()
											question.media_url = ''
										" />
									<label :for="`question${index}mediaTypeImage`">{{
										translate('mediaType.image')
									}}</label>

									<input
										:id="`question${index}mediaTypeAudio`"
										v-model="question.media_type"
										type="radio"
										:name="`question${index}mediaType`"
										value="audio"
										@change="
											fieldUpdate()
											question.media_url = ''
										" />
									<label :for="`question${index}mediaTypeAudio`">{{
										translate('mediaType.audio')
									}}</label>

									<input
										:id="`question${index}mediaTypeVideo`"
										v-model="question.media_type"
										type="radio"
										:name="`question${index}mediaType`"
										value="video"
										@change="
											fieldUpdate()
											question.media_url = ''
										" />
									<label :for="`question${index}mediaTypeVideo`">{{
										translate('mediaType.video')
									}}</label>
								</div>

								<div class="actions">
									<button
										v-if="playedBy === 0"
										v-tippy
										class="delete icon"
										:content="translate('quiz.deleteQuestion')"
										@click="deleteQuestion(question.id)">
										<Icon name="trash-2" fill="red" />
									</button>

									<button
										v-tippy
										class="icon"
										:content="translate('quiz.previewQuestion')"
										@click="previewQuestion(index)">
										<Icon name="eye" fill="gray" />
									</button>
								</div>
							</div>

							<div class="row auto">
								<div
									v-if="['image', 'video'].includes(question.media_type) && question.media_url"
									class="column no-grow">
									<div v-if="question.media_type === 'image'" class="image-preview">
										<ImageComponent
											:id="`question${index}image-preview`"
											:height="118"
											:width="222"
											:src="question.media_url"
											:overlay="true"
											alt="Image Preview" />
									</div>

									<div v-if="question.media_type === 'video'" class="video-preview">
										<VideoThumbnail :src="question.media_url" />
									</div>
								</div>
								<div class="column">
									<div
										class="form-input"
										:class="{
											error: error[`question${index}`],
										}"
										:data-error="error[`question${index}`]">
										<div class="label">
											<label :for="`question${index}`">{{ translate('quiz.question') }}</label>
											<p
												v-if="!readonly"
												v-tippy
												:class="[
													'char-count',
													{
														'text-red':
															(question.question ? question.question.length : 0) >
															characterLimits.questionTitle,
													},
												]"
												:content="translate('quiz.maxCharacterLength')"
												tabindex="-1">
												{{ question.question ? question.question.length : 0 }}/{{
													characterLimits.questionTitle
												}}
											</p>
										</div>
										<input
											:id="`question${index}`"
											v-model="question.question"
											type="text"
											:class="[
												`state-${
													state[
														(`question${index}`,
														{
															readonly: playedBy > 0,
														})
													]
												}`,
											]"
											:data-maxlength="characterLimits.questionTitle"
											:placeholder="translate('quiz.question')"
											:readonly="readonly"
											@input="fieldUpdate($event)" />
										<InputState
											:state="state[`question${index}`]"
											:show="!!state[`question${index}`]" />
									</div>

									<div
										v-if="question.media_type === 'image'"
										class="form-input image"
										:class="{
											error: error[`question${index}image`],
										}"
										:data-error="error[`question${index}image`]">
										<div class="label">
											<label :for="`question${index}image`">{{ translate('quiz.imageUrl') }}</label>
											<p class="notice">
												{{ translate('quiz.imageUrl.notice') }}
											</p>
										</div>
										<div class="input-container">
											<input
												:id="`question${index}image`"
												v-model="question.media_url"
												type="text"
												:class="[
													`state-${
														state[
															(`question${index}image`,
															{
																readonly: playedBy > 0,
															})
														]
													}`,
												]"
												:placeholder="translate('quiz.imageUrl.placeholder')"
												:readonly="readonly"
												:style="{
													paddingRight: `${230}px`,
												}"
												@input="fieldUpdate($event)" />
											<InputState
												:state="state[`question${index}image`]"
												:show="!!state[`question${index}image`]"
												:style="{
													right: '200px',
												}" />
											<div class="buttons">
												<button
													class="small icon-left"
													@click="
														selectImage({
															model: question,
															id: `question${index}image`,
														})
													">
													<Icon name="image" fill="white" width="14" />
													<span>{{ translate('quiz.chooseImage') }}</span>
												</button>
												<UploadInput
													:id="`image-upload-${index}`"
													:ref-id="`question${index}image`"
													:model="question"
													bucket="quiz-images"
													classes="small icon-left"
													icon="cloud-upload-outline"
													:text="translate('quiz.uploadImage')"
													@uploaded="handleUpload" />
											</div>
										</div>
									</div>

									<div
										v-if="question.media_type === 'video'"
										class="form-input video"
										:class="{
											error: error[`question${index}video`],
										}"
										:data-error="error[`question${index}video`]">
										<div class="label">
											<label :for="`question${index}video`">{{ translate('quiz.videoUrl') }}</label>
											<p class="notice">
												{{ translate('quiz.videoUrl.notice') }}
											</p>
										</div>
										<input
											:id="`question${index}video`"
											v-model="question.media_url"
											type="text"
											:class="[
												`state-${
													state[
														(`question${index}video`,
														{
															readonly: playedBy > 0,
														})
													]
												}`,
											]"
											:placeholder="translate('quiz.videoUrl.placeholder')"
											:readonly="readonly"
											@input="fieldUpdate($event)" />
										<InputState
											:state="state[`question${index}video`]"
											:show="!!state[`question${index}video`]" />
									</div>
								</div>
							</div>

							<div
								v-if="question.media_type === 'audio'"
								class="form-input audio"
								:class="{
									error: error[`question${index}audio`],
								}"
								:data-error="error[`question${index}audio`]">
								<div class="label">
									<label :for="`question${index}audio`">{{ translate('quiz.audioUrl') }}</label>
									<p class="notice">
										{{ translate('quiz.audioUrl.notice') }}
									</p>
								</div>
								<input
									:id="`question${index}audio`"
									v-model="question.media_url"
									type="text"
									:class="[
										`state-${
											state[
												(`question${index}audio`,
												{
													readonly: playedBy > 0,
												})
											]
										}`,
									]"
									:placeholder="translate('quiz.audioUrl.placeholder')"
									:readonly="readonly"
									@input="fieldUpdate($event)" />
								<InputState
									:state="state[`question${index}audio`]"
									:show="!!state[`question${index}audio`]" />
							</div>

							<div class="row auto" style="margin-top: 10px">
								<transition name="fade">
									<div
										v-if="question.media_type === 'audio' && question.media_url"
										class="audio-preview column">
										<Audio :url="question.media_url" />
									</div>
								</transition>
							</div>

							<div class="row three">
								<div
									v-for="(answer, i) in question.options"
									:key="i"
									class="form-input column"
									:class="{
										error: error[`question${index}answer${i}`],
									}"
									:data-error="error[`question${index}answer${i}`]">
									<div class="label">
										<label :for="`question${index}answer${i}`">{{
											i === 0 ? translate('quiz.correctAnswer') : translate('quiz.wrongAnswer')
										}}</label>
										<p
											v-if="!readonly"
											v-tippy
											:class="[
												'char-count',
												{
													'text-red': question.options[i].length > characterLimits.questionAnswer,
												},
											]"
											:content="translate('quiz.maxCharacterLength')"
											tabindex="-1">
											{{ question.options[i].length }}/{{ characterLimits.questionAnswer }}
										</p>
									</div>
									<input
										:id="`question${index}answer${i}`"
										v-model="question.options[i]"
										type="text"
										:class="[
											`state-${
												state[
													(`question${index}answer${i}`,
													{
														readonly: playedBy > 0,
													})
												]
											}`,
										]"
										:data-maxlength="characterLimits.questionAnswer"
										:placeholder="
											i === 0 ? translate('quiz.correctAnswer') : translate('quiz.wrongAnswer')
										"
										:readonly="readonly"
										@input="fieldUpdate($event, index)" />
									<InputState
										:state="state[`question${index}answer${i}`]"
										:show="!!state[`question${index}answer${i}`]" />
								</div>
							</div>
						</div>
					</TransitionGroup>
				</div>
			</div>

			<Transition name="fade" appear>
				<button
					v-if="questions.length < questionsLimit && !readonly"
					class="add-question"
					:style="{
						transitionDelay: `${1 + questions.length * 0.1}s`,
					}"
					@click="addQuestion">
					<Icon name="plus-square" fill="white" />
					{{ translate('quiz.addQuestion') }}
				</button>
			</Transition>

			<Transition name="fade" appear>
				<div
					v-if="!readonly && !quiz.public"
					class="delete-section"
					:style="{
						transitionDelay: `${1.1 + questions.length * 0.1}s`,
					}">
					<div class="inner">
						<div>
							<label>{{ translate('quiz.deleteQuiz.label') }}</label>
							<p>
								{{ translate('quiz.deleteQuiz.description') }}
							</p>
						</div>
						<button class="red" @click="deleteQuiz">
							{{ translate('delete') }}
						</button>
					</div>
				</div>
			</Transition>
		</div>

		<transition name="fade">
			<PreviewQuestion v-show="preview" :question="preview" @close="preview = null" />
		</transition>

		<transition name="fade">
			<div v-show="previewStartScreen" class="start-screen-preview">
				<StartScreen v-if="previewStartScreen" :quiz="previewStartScreen" :preview="true" />
				<button
					class="icon-left"
					@click="
						previewStartScreen = null
						toggleNoScroll()
					">
					<Icon name="close" width="20" fill="white" />{{ translate('close') }}
				</button>
			</div>
		</transition>

		<ImageSelector v-if="imageSelect" :model="imageSelect" @close="selectImage" />

		<div v-if="!loading && (notCreatedByMe || quizDoesNotExist)" class="loading-error">
			<div v-if="notCreatedByMe">
				<div v-if="quiz" class="quiz-details">
					<h3>'{{ quiz.title }}'</h3>
					<p>
						{{ translate('madeBy') }}
						<User :user="quiz.createdBy" :clickable="true" />
					</p>
				</div>

				<div class="alert red">
					<Icon name="alert-triangle" />
					<p>
						{{ translate('quiz.notice.notMadeByYou') }}
					</p>
				</div>

				<MyQuizzes />
			</div>

			<div v-if="quizDoesNotExist">
				<h3>
					{{ translate('quiz.404') }}
				</h3>

				<div class="alert red">
					<Icon name="alert-triangle" />
					<p>
						{{ translate('quiz.404.first') }}
						"<span style="font-weight: 600">{{ $route.params.id }}</span
						>".
						{{ translate('quiz.404.second') }}
					</p>
				</div>

				<MyQuizzes />
			</div>
		</div>
	</div>
</template>

<script>
import { auth, db } from '@/lib/supabase'
import { nanoid } from 'nanoid'
import { debounce } from 'debounce'
import { copyToClipboard, handleError } from '@/utils/utils'
import gsap from 'gsap'

import Loader from '@/components/Loader'
import InputState from '@/components/InputState'
import User from '@/components/User'
import MyQuizzes from '@/components/MyQuizzes'
import PreviewQuestion from '@/components/PreviewQuestion'
import Analytics from '@/components/Analytics'
import StartScreen from '@/components/StartScreen'
import ImageSelector from '@/components/ImageSelector'
import UploadInput from '@/components/UploadInput'
import ImageComponent from '@/components/ImageComponent'
import VideoThumbnail from '@/components/VideoThumbnail'
import Audio from '@/components/mediaTypes/Audio'
import Icon from '../components/Icon.vue'

export default {
	components: {
		Loader,
		InputState,
		User,
		MyQuizzes,
		PreviewQuestion,
		Analytics,
		StartScreen,
		ImageSelector,
		UploadInput,
		ImageComponent,
		VideoThumbnail,
		Audio,
		Icon,
	},

	data() {
		return {
			quiz: null,

			questions: [],

			id: null,

			loading: true,

			notCreatedByMe: false,

			playedBy: 0,

			quizDoesNotExist: false,

			questionsHidden: false,

			adminMode: false,

			error: {
				title: null,
				qid: null,
				startDate: null,
				endDate: null,
			},

			state: {
				title: null,
				qid: null,
				description: null,
				cover_url: null,
				startDate: null,
				endData: null,

				// Question and answer states
				// Need to define them here for the input state management to work (???)
				// TODO: Figure out why
				question0: null,
				question0audio: null,
				question0image: null,
				question0video: null,
				question0answer0: null,
				question0answer1: null,
				question0answer2: null,
				question1: null,
				question1audio: null,
				question1image: null,
				question1video: null,
				question1answer0: null,
				question1answer1: null,
				question1answer2: null,
				question2: null,
				question2audio: null,
				question2image: null,
				question2video: null,
				question2answer0: null,
				question2answer1: null,
				question2answer2: null,
				question3: null,
				question3audio: null,
				question3image: null,
				question3video: null,
				question3answer0: null,
				question3answer1: null,
				question3answer2: null,
				question4: null,
				question4audio: null,
				question4image: null,
				question4video: null,
				question4answer0: null,
				question4answer1: null,
				question4answer2: null,
				question5: null,
				question5audio: null,
				question5image: null,
				question5video: null,
				question5answer0: null,
				question5answer1: null,
				question5answer2: null,
				question6: null,
				question6audio: null,
				question6image: null,
				question6video: null,
				question6answer0: null,
				question6answer1: null,
				question6answer2: null,
				question7: null,
				question7audio: null,
				question7image: null,
				question7video: null,
				question7answer0: null,
				question7answer1: null,
				question7answer2: null,
				question8: null,
				question8audio: null,
				question8image: null,
				question8video: null,
				question8answer0: null,
				question8answer1: null,
				question8answer2: null,
				question9: null,
				question9audio: null,
				question9image: null,
				question9video: null,
				question9answer0: null,
				question9answer1: null,
				question9answer2: null,
				question10: null,
				question10audio: null,
				question10image: null,
				question10video: null,
				question10answer0: null,
				question10answer1: null,
				question10answer2: null,
				question11: null,
				question11audio: null,
				question11image: null,
				question11video: null,
				question11answer0: null,
				question11answer1: null,
				question11answer2: null,
				question12: null,
				question12audio: null,
				question12image: null,
				question12video: null,
				question12answer0: null,
				question12answer1: null,
				question12answer2: null,
				question13: null,
				question13audio: null,
				question13image: null,
				question13video: null,
				question13answer0: null,
				question13answer1: null,
				question13answer2: null,
				question14: null,
				question14audio: null,
				question14image: null,
				question14video: null,
				question14answer0: null,
				question14answer1: null,
				question14answer2: null,
				question15: null,
				question15audio: null,
				question15image: null,
				question15video: null,
				question15answer0: null,
				question15answer1: null,
				question15answer2: null,
				question16: null,
				question16audio: null,
				question16image: null,
				question16video: null,
				question16answer0: null,
				question16answer1: null,
				question16answer2: null,
				question17: null,
				question17audio: null,
				question17image: null,
				question17video: null,
				question17answer0: null,
				question17answer1: null,
				question17answer2: null,
				question18: null,
				question18audio: null,
				question18image: null,
				question18video: null,
				question18answer0: null,
				question18answer1: null,
				question18answer2: null,
				question19: null,
				question19audio: null,
				question19image: null,
				question19video: null,
				question19answer0: null,
				question19answer1: null,
				question19answer2: null,
			},

			questionsLimit: 20,

			characterLimits: {
				quizDescription: 400,
				quizQid: 40,
				quizTitle: 80,
				questionTitle: 400,
				questionAnswer: 200,
			},

			updateQueue: [],

			preview: null,

			previewStartScreen: null,

			imageSelect: null,
		}
	},

	computed: {
		baseUrl() {
			return window.location.origin + '/q/'
		},

		environment() {
			return this.$store.getters.environment
		},

		readonly() {
			return this.playedBy > 0 && !this.adminMode
		},

		userIsAdmin() {
			return this.$store.getters.isAdmin
		},
	},

	async beforeMount() {
		let id = this.$route.params.id

		if (id) {
			await this.getQuiz(id)
			await this.getQuestions()
		} else {
			await this.initQuiz()
		}
	},

	methods: {
		async getQuiz(qid) {
			const { data: quiz, error } = await db
				.from('quizzes')
				.select('*')
				.eq('qid', qid)
				.limit(1)
				.maybeSingle()

			if (error) handleError(error)
			else {
				if (!quiz) {
					this.quizDoesNotExist = true
					this.loading = false
					return
				}

				if (quiz.created_by !== auth.user().id) {
					this.notCreatedByMe = true
					return
				}

				this.quiz = quiz
				this.id = quiz.id
				this.playedBy = await this.getAttempts()
				this.adminMode = this.userIsAdmin && quiz.created_by !== auth.user().id
				this.questionsHidden = false
				this.loading = false
			}
		},

		async getQuestions() {
			const { data: questions, error } = await db
				.from('questions')
				.select('*')
				.eq('quiz_id', this.id)
				.order('order')
			if (error) handleError(error)
			else this.questions = questions
		},

		async getAttempts() {
			const { data: attempts, error } = await db.from('attempts').select('*').eq('quiz_id', this.id)
			if (error) handleError(error)
			else return attempts.length
		},

		async initQuiz() {
			const qid = nanoid(8)
			const { error } = await db.rpc('init_new_quiz', {
				qid_param: qid,
				title_param: this.translate('quiz.defaultTitle'),
				user_id_param: auth.user().id,
			})

			if (error) handleError(error)
			else {
				setTimeout(() => {
					this.$router.push(`/builder/${qid}`)
				}, 100)
			}
		},

		async addQuestion() {
			const { error } = await db.from('questions').insert([
				{
					quiz_id: this.quiz.id,
					order: this.questions.length + 1,
				},
			])

			if (error) handleError(error)
			else await this.getQuestions()
		},

		async deleteQuestion(id) {
			// TODO: Add confirm modal before deleting, if question is not empty
			const { error } = await db.from('questions').delete().match({
				id,
			})
			if (error) handleError(error)
			else await this.getQuestions()
		},

		async deleteQuiz() {
			// TODO: Add notification that quiz was successfully deleted
			// TODO: Add confirm modal before deleting
			const username = this.$store.getters.user.username
			const { error } = await db.rpc('delete_quiz', {
				quiz_id_param: this.quiz.id,
			})
			if (error) handleError(error)
			else {
				this.$notify({
					title: this.translate('quiz.successfullyDeleted'),
					type: 'success',
					data: {
						icon: 'checkmark-circle-2',
					},
				})
				this.$router.push(`/profile/@${username}`)
			}
		},

		previewQuestion(index) {
			this.preview = this.questions[index]
			document.body.classList.add('no-scroll')
		},

		previewStart() {
			this.previewStartScreen = {
				...this.quiz,
				questions: this.questions,
				completedBy: this.playedBy,
			}
			this.toggleNoScroll()
		},

		toggleNoScroll() {
			const classList = document.body.classList

			if (classList.contains('no-scroll')) {
				classList.remove('no-scroll')
			} else {
				classList.add('no-scroll')
			}
		},

		selectImage({ model, id, image, close }) {
			if (!model) return

			if (image) {
				document.getElementById(model.refId).dispatchEvent(new Event('input'))

				if (model.model === 'cover_url') {
					this.quiz.cover_url = image.src.large
				} else {
					const question = this.questions.find((question) => question.id === model.model.id)
					question.media_url = image.src.large
					document.getElementById(model.refId + '-preview').src = image.src.large
				}
				this.imageSelect = null
				this.toggleNoScroll()
			} else if (close) {
				this.imageSelect = null
				this.toggleNoScroll()
			} else {
				this.imageSelect = {
					model,
					refId: id,
				}
				this.toggleNoScroll()
			}
		},

		handleUpload(url, model, refId) {
			if (refId) {
				document.getElementById(refId).dispatchEvent(new Event('input'))
			}

			if (model === 'cover_url') {
				this.quiz.cover_url = url
			} else {
				const question = this.questions.find((question) => question.id === model.id)
				question.media_url = url
			}
		},

		async updateSupabase(id) {
			const quizError = await this.updateQuiz(id)
			const questionsError = await this.updateQuestions(id)

			if (quizError) handleError(quizError)
			if (questionsError) handleError(questionsError)
		},

		async updateQuiz(id) {
			if (this.quiz.public) this.quiz.published_at = new Date()
			else this.quiz.published_at = null

			const { error } = await db
				.from('quizzes')
				.update({
					...this.quiz,
				})
				.match({
					id: this.id,
				})

			if (error) {
				this.handleFormError(error, id)
			} else {
				this.clearState(id, 'success')
			}

			return error
		},

		async updateQuestions(id) {
			for (let [index, question] of this.questions.entries()) {
				question = {
					...question,
					order: index + 1,
				}

				const { error } = await db
					.from('questions')
					.update({
						...question,
					})
					.match({
						id: question.id,
					})

				if (error) {
					this.handleFormError(error, id)
					return error
				} else {
					this.clearState(id, 'success')
					continue
				}
			}
		},

		async updateQid(e) {
			if (e.target.value === this.quiz.qid) return

			const { data, error } = await db.from('quizzes').select('*').eq('qid', e.target.value)

			if (error) handleError(error)
			else {
				if (!data || data.length === 0) {
					this.debouncedUpdate(e.target.id)
				} else {
					this.state.qid = 'error'
					this.error.qid = this.translate('quiz.qidAlreadyExists')
				}
			}
		},

		fieldUpdate(e, index) {
			if (e) {
				this.error[e.target.id] = null
				this.state[e.target.id] = 'loading'

				// Add correct answer to its field
				if (index || index === 0) {
					this.questions[index].correct_answer = this.questions[index].options[0]
				}

				if (e.target.dataset.maxlength && e.target.value.length > e.target.dataset.maxlength) {
					this.error[e.target.id] = this.translate('quiz.error.characterMaxLength')
					this.state[e.target.id] = 'error'
					return
				}

				this.updateQueue.push(e.target.id)
				this.debouncedUpdate(e.target.id)
			} else {
				this.debouncedUpdate(null)
			}
		},

		handleFormError(error, id) {
			if (id) {
				this.error[id] = error.message
				this.state[id] = 'error'
				this.clearUpdateQueue('error')
			} else {
				handleError(error)
			}
		},

		clearState(id, type) {
			this.state[id] = type || null
			this.clearUpdateQueue(type)

			setTimeout(() => {
				this.state[id] = null
			}, 2000)
		},

		clearUpdateQueue(status) {
			this.updateQueue.forEach((id) => {
				if (id !== this.updateQueue.length - 1) {
					if (status === 'success') {
						this.state[id] = 'success'

						setTimeout(() => {
							this.state[id] = null
						}, 2000)
					} else {
						this.state[id] = null
					}
				}
			})

			this.updateQueue = []
		},

		debounceQid: debounce(function (e) {
			this.updateQid(e)
		}, 2500),

		debouncedUpdate: debounce(function (id) {
			this.updateSupabase(id)
		}, 2500),

		copy(e, url) {
			e.target.classList.add('green')
			e.target.children[1].innerHTML = this.translate('copied')

			this.copyToClipboard(url)

			setTimeout(() => {
				e.target.classList.remove('green')
				e.target.children[1].innerHTML = this.translate('copy')
			}, 1500)
		},

		mounted() {
			if (this.environment !== 'production') {
				console.log({
					quiz: this.quiz,
				})
			}
		},

		beforeEnter(el) {
			el.style.opacity = 0
			el.style.transform = 'translateY(30px)'
		},

		enter(el, done) {
			gsap.to(el, {
				opacity: 1,
				y: 0,
				duration: 0.8,
				onComplete: done,
				delay: 0.5 + el.dataset.index * 0.1,
			})
		},

		afterEnter(el) {
			el.style.transform = 'none'
		},

		copyToClipboard,
	},
}
</script>

<style lang="scss" scoped>
.heading {
	text-align: center;
}

.builder {
	transition: $transition;

	.public-toggle {
		position: relative;

		input {
			display: none;

			&:checked + label {
				span {
					border-color: $green;

					&:last-child {
						color: white;
					}
				}

				&::after {
					background-color: $green;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
					left: 50%;
				}
			}
		}

		label {
			cursor: pointer;
			display: flex;
			left: 0;
			margin: 0;
			position: relative;

			span {
				border: 2px solid lighten($yellow, 5%);
				border-radius: $border-radius;
				color: darken(white, 75%);
				font-size: 14px;
				font-weight: 500;
				text-align: center;
				padding: 9px 12px;
				width: 50%;

				&:first-child {
					border-right: none;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}

				&:last-child {
					border-left: none;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}

			&::after {
				background-color: lighten($yellow, 5%);
				border-top-left-radius: $border-radius;
				border-bottom-left-radius: $border-radius;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				content: '';
				height: 100%;
				left: 0;
				position: absolute;
				z-index: -1;
				transition: $transition;
				top: 0;
				width: 50%;
			}
		}
	}

	.analytics {
		margin: 50px 0 25px;
	}

	.questions-wrapper {
		margin: 50px 0 25px;
		transition: 0.25s ease;

		&.hidden {
			transform: scaleY(0);
		}

		.title {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;

			p {
				font-weight: 600;

				&.hide-questions {
					align-items: center;
					cursor: pointer;
					display: flex;
					opacity: 0.4;
					transition: 0.25s ease;

					&:hover {
						opacity: 0.6;
					}

					.icon i {
						margin-left: 5px;
						position: relative;
						top: 2px;
						transition: 0.25s ease;

						&.hidden {
							transform: scaleY(-1);
							top: -2px;
						}
					}
				}
			}

			.actions {
				position: relative;

				button {
					opacity: 0.5;

					&:hover {
						opacity: 1;
					}

					+ button {
						left: calc(-100% - 15px);
						position: absolute;
						top: 1px;
					}
				}
			}
		}

		.alert + .questions {
			margin-top: 20px;
		}

		.questions {
			&.hidden {
				display: none;
			}
		}

		.image-preview {
			height: 138px;
			margin-right: 8px;
			position: relative;
			width: 245px;
			z-index: 1;

			img {
				border-radius: $border-radius;
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}

		.video-preview {
			height: 138px;
			margin-right: 8px;
			position: relative;
			width: 245px;
			z-index: 1;

			.video-thumbnail {
				border-radius: $border-radius;
				overflow: hidden;
			}
		}

		.question {
			background: darken(white, 5%);
			border-radius: 8px;
			padding: 20px;

			@include mobile {
				padding: 15px;
			}

			+ .question {
				margin-top: 25px;
			}

			.title {
				@include mobile {
					flex-wrap: wrap;

					h4 {
						width: 50%;
					}

					.actions {
						flex-grow: 0;
					}

					.radio {
						order: 3;
						margin: 15px 0 6px;
						width: 100%;
					}
				}

				h4 {
					color: darken(white, 30%);
					font-size: 18px;
				}
			}
		}
	}

	button.add-question {
		align-items: center;
		display: flex;
		font-size: 18px;
		margin: 0 auto;

		.icon i {
			margin-right: 8px;
			position: relative;
			top: 1px;
		}
	}

	.delete-section {
		border-top: 2px dashed darken(white, 15%);
		margin-top: 75px;
		padding-top: 30px;

		.inner {
			align-items: center;
			background: rgba($red, 0.1);
			border: 2px solid rgba($red, 0.35);
			border-radius: $border-radius;
			color: darken($red, 20%);
			display: flex;
			justify-content: space-between;
			padding: 15px;

			label {
				left: 0;
			}
		}
	}
}

.loading-error {
	text-align: center;

	h3 {
		margin-bottom: 20px;
	}

	.quiz-details {
		margin: 25px 0 50px;

		h3 {
			margin-bottom: 20px;
		}

		p {
			align-items: center;
			display: inline-flex;

			::v-deep .user {
				margin-left: 10px;
			}
		}
	}
}

.start-screen-preview {
	background: rgba(white, 0.75);
	height: 100%;
	left: 0;
	overflow-y: auto;
	padding-bottom: 75px;
	position: fixed;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 10;

	::v-deep.start-screen {
		margin: 100px auto 0;
		max-width: 1000px;
	}

	button {
		margin-top: 50px;
	}
}
</style>
