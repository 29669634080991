var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[(_vm.loading)?_c('div',{staticClass:"loader-container fullscreen"},[_c('Loader',{attrs:{"size":50,"border-width":6}})],1):_vm._e(),(!_vm.loading && _vm.quiz && !_vm.notCreatedByMe && !_vm.quizDoesNotExist)?_c('div',{staticClass:"builder",style:({
			filter: _vm.preview ? 'blur(7px)' : null,
		})},[_c('Transition',{attrs:{"name":"fade","appear":""}},[(_vm.adminMode)?_c('div',{staticClass:"alert dev purple full-width text-center",staticStyle:{"margin-bottom":"50px"}},[_c('Icon',{attrs:{"name":"alert-triangle"}}),_c('p',[_vm._v(" Admin Mode is enabled, be careful! Quiz ID: "),_c('b',[_vm._v(_vm._s(_vm.id))])])],1):_vm._e()]),_c('TransitionGroup',{attrs:{"name":"fade-slide-up","appear":""}},[_c('div',{key:1,staticClass:"row auto"},[_c('div',{staticClass:"form-input column",class:{
						error: _vm.error.title,
					},attrs:{"id":"quiz-title","data-error":_vm.error.title}},[_c('div',{staticClass:"label"},[_c('label',{attrs:{"for":"title"}},[_vm._v(_vm._s(_vm.translate('quiz.title')))]),_c('p',{directives:[{name:"tippy",rawName:"v-tippy"}],class:[
								'char-count',
								{
									'text-red': (_vm.quiz.title ? _vm.quiz.title.length : 0) > _vm.characterLimits.quizTitle,
								},
							],attrs:{"content":_vm.translate('quiz.maxCharacterLength'),"tabindex":"-1"}},[_vm._v(" "+_vm._s(_vm.quiz.title ? _vm.quiz.title.length : 0)+"/"+_vm._s(_vm.characterLimits.quizTitle)+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quiz.title),expression:"quiz.title"}],class:[`state-${_vm.state.title}`],attrs:{"id":"title","type":"text","data-maxlength":_vm.characterLimits.quizTitle,"placeholder":_vm.translate('quiz.title.placeholder')},domProps:{"value":(_vm.quiz.title)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.quiz, "title", $event.target.value)},function($event){return _vm.fieldUpdate($event)}]}}),_c('InputState',{attrs:{"state":_vm.state.title,"show":!!_vm.state.title}})],1),_c('div',{staticClass:"form-input column",class:{
						error: _vm.error.qid,
					},attrs:{"id":"quiz-qid","data-error":_vm.error.qid}},[_c('div',{staticClass:"label"},[_c('label',{attrs:{"for":"qid"}},[_vm._v(_vm._s(_vm.translate('quiz.id')))]),_c('p',{staticClass:"notice"},[_vm._v(" "+_vm._s(_vm.translate('mustBeUnique'))+" ")]),_c('p',{directives:[{name:"tippy",rawName:"v-tippy"}],class:[
								'char-count',
								{
									'text-red': (_vm.quiz.qid ? _vm.quiz.qid.length : 0) > _vm.characterLimits.quizQid,
								},
							],attrs:{"content":_vm.translate('quiz.maxCharacterLength'),"tabindex":"-1"}},[_vm._v(" "+_vm._s(_vm.quiz.qid ? _vm.quiz.qid.length : 0)+"/"+_vm._s(_vm.characterLimits.quizQid)+" ")])]),_c('div',{staticClass:"input-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quiz.qid),expression:"quiz.qid"}],class:[`state-${_vm.state.qid}`, 'prepended'],attrs:{"id":"qid","type":"text","data-maxlength":_vm.characterLimits.quizQid,"placeholder":_vm.translate('quiz.id.placeholder')},domProps:{"value":(_vm.quiz.qid)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.quiz, "qid", $event.target.value)},function($event){_vm.error.qid = null
								_vm.state.qid = 'loading'
								_vm.debounceQid($event)}]}}),_c('div',{staticClass:"prepend"},[_vm._v(" "+_vm._s(_vm.baseUrl)+" ")]),_c('InputState',{style:({
								right: '90px',
							}),attrs:{"state":_vm.state.qid,"show":!!_vm.state.qid}}),_c('button',{staticClass:"small icon-left",on:{"click":function($event){return _vm.copy($event, _vm.baseUrl + _vm.quiz.qid)}}},[_c('Icon',{attrs:{"name":"clipboard","fill":"white","width":"14"}}),_c('span',[_vm._v(_vm._s(_vm.translate('copy')))])],1)],1)]),_c('div',{staticClass:"form-input column"},[_c('div',{staticClass:"label"},[_c('label',{attrs:{"for":"public"}},[_vm._v(_vm._s(_vm.translate('quiz.privacy')))]),_c('p',{staticClass:"notice"},[_c('Icon',{attrs:{"name":"question-mark-circle","width":"17","fill":"gray","vtippy":true,"content":_vm.translate('quiz.privacy.tooltip')}})],1)]),_c('div',{staticClass:"public-toggle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quiz.public),expression:"quiz.public"}],attrs:{"id":"public","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.quiz.public)?_vm._i(_vm.quiz.public,null)>-1:(_vm.quiz.public)},on:{"change":[function($event){var $$a=_vm.quiz.public,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.quiz, "public", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.quiz, "public", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.quiz, "public", $$c)}},function($event){return _vm.fieldUpdate()}]}}),_c('label',{attrs:{"for":"public"}},[_c('span',[_vm._v(_vm._s(_vm.translate('quiz.privacy.private')))]),_c('span',[_vm._v(_vm._s(_vm.translate('quiz.privacy.public')))])])])])]),_c('div',{key:2,staticClass:"row auto",staticStyle:{"transition-delay":"0.1s"}},[_c('div',{staticClass:"form-input column",class:{
						error: _vm.error.description,
					},attrs:{"data-error":_vm.error.description}},[_c('div',{staticClass:"label"},[_c('label',{attrs:{"for":"description"}},[_vm._v(_vm._s(_vm.translate('quiz.description')))]),_c('p',{directives:[{name:"tippy",rawName:"v-tippy"}],class:[
								'char-count',
								{
									'text-red':
										(_vm.quiz.description ? _vm.quiz.description.length : 0) >
										_vm.characterLimits.quizDescription,
								},
							],attrs:{"content":_vm.translate('quiz.maxCharacterLength'),"tabindex":"-1"}},[_vm._v(" "+_vm._s(_vm.quiz.description ? _vm.quiz.description.length : 0)+"/"+_vm._s(_vm.characterLimits.quizDescription)+" ")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.quiz.description),expression:"quiz.description"}],class:[`state-${_vm.state.description}`],style:({
							maxHeight: '120px',
						}),attrs:{"id":"description","rows":"3","data-maxlength":_vm.characterLimits.quizDescription,"placeholder":_vm.translate('quiz.description.placeholder')},domProps:{"value":(_vm.quiz.description)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.quiz, "description", $event.target.value)},function($event){return _vm.fieldUpdate($event)}]}}),_c('InputState',{attrs:{"state":_vm.state.description,"show":!!_vm.state.description}})],1)]),_c('div',{key:3,staticClass:"row auto",staticStyle:{"transition-delay":"0.2s"}},[_c('div',{staticClass:"form-input column",class:{
						error: _vm.error.cover_url,
					},attrs:{"data-error":_vm.error.cover_url}},[_c('div',{staticClass:"label"},[_c('label',{attrs:{"for":"cover_url"}},[_vm._v(_vm._s(_vm.translate('quiz.cover_url')))]),_c('p',{staticClass:"notice"},[_vm._v(" "+_vm._s(_vm.translate('quiz.imageUrl.notice'))+" ")])]),_c('div',{staticClass:"input-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quiz.cover_url),expression:"quiz.cover_url"}],class:[`state-${_vm.state.cover_url}`],style:({
								paddingRight: '240px',
							}),attrs:{"id":"cover_url","type":"text","placeholder":_vm.translate('quiz.cover_url.placeholder')},domProps:{"value":(_vm.quiz.cover_url)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.quiz, "cover_url", $event.target.value)},function($event){return _vm.fieldUpdate($event)}]}}),_c('InputState',{style:({
								right: '230px',
							}),attrs:{"state":_vm.state.cover_url,"show":!!_vm.state.cover_url}}),_c('div',{staticClass:"buttons"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"small icon",attrs:{"content":_vm.translate('quiz.cover_url.preview')},on:{"click":function($event){return _vm.previewStart()}}},[_c('Icon',{style:({
										opacity: 0.7,
									}),attrs:{"name":"eye","fill":"gray","width":"20"}})],1),_c('button',{staticClass:"small icon-left",on:{"click":function($event){return _vm.selectImage({
										model: 'cover_url',
										id: 'cover_url',
									})}}},[_c('Icon',{attrs:{"name":"image","fill":"white","width":"14"}}),_c('span',[_vm._v(_vm._s(_vm.translate('quiz.chooseImage')))])],1),_c('UploadInput',{attrs:{"id":"cover-image-upload","ref-id":"cover_url","model":"cover_url","bucket":"quiz-images","classes":"small icon-left","icon":"cloud-upload-outline","text":_vm.translate('quiz.uploadImage')},on:{"uploaded":_vm.handleUpload}})],1)],1)])])]),_c('Transition',{attrs:{"name":"fade-slide-up","appear":""}},[(_vm.readonly || _vm.adminMode)?_c('Analytics',{staticStyle:{"transition-delay":"0.3s"},attrs:{"quiz":_vm.quiz,"admin-mode":_vm.adminMode}}):_vm._e()],1),_c('div',{staticClass:"questions-wrapper"},[_c('Transition',{attrs:{"name":"fade-slide-up","appear":""}},[_c('div',{staticClass:"title",staticStyle:{"transition-delay":"0.4s"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.translate('quiz.questions'))+" ")]),(!_vm.readonly)?_c('p',[_vm._v(" "+_vm._s(_vm.questions.length)+" / "+_vm._s(_vm.questionsLimit)+" ")]):_vm._e(),(_vm.readonly)?_c('p',{staticClass:"hide-questions",on:{"click":function($event){_vm.questionsHidden = !_vm.questionsHidden}}},[_c('span',[_vm._v(_vm._s(_vm.questionsHidden ? _vm.translate('quiz.showQuestions') : _vm.translate('quiz.hideQuestions')))]),_c('Icon',{class:{
								hidden: _vm.questionsHidden,
							},attrs:{"name":"arrowhead-up","width":"20"}})],1):_vm._e()])]),_c('Transition',{attrs:{"name":"fade-slide-up","appear":""}},[(_vm.readonly)?_c('div',{staticClass:"alert blue full-width",staticStyle:{"transition-delay":"0.5s"}},[_c('Icon',{attrs:{"name":"info"}}),_c('p',[_vm._v(" "+_vm._s(_vm.translate('quiz.notice.questionsLockedOncePlayed'))+" ")])],1):_vm._e()]),_c('div',{class:[
					{
						hidden: _vm.questionsHidden,
					},
					'questions',
				]},[_c('TransitionGroup',{on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},_vm._l((_vm.questions),function(question,index){return _c('div',{key:index,staticClass:"question",attrs:{"data-index":index}},[_c('div',{staticClass:"title"},[_c('h4',[_vm._v("#"+_vm._s(index + 1))]),(!_vm.readonly)?_c('div',{staticClass:"mediaType radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.media_type),expression:"question.media_type"}],attrs:{"id":`question${index}mediaTypeText`,"type":"radio","name":`question${index}mediaType`,"value":"text"},domProps:{"checked":_vm._q(question.media_type,"text")},on:{"change":[function($event){return _vm.$set(question, "media_type", "text")},function($event){_vm.fieldUpdate()
										question.media_url = ''}]}}),_c('label',{attrs:{"for":`question${index}mediaTypeText`}},[_vm._v(_vm._s(_vm.translate('mediaType.text')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.media_type),expression:"question.media_type"}],attrs:{"id":`question${index}mediaTypeImage`,"type":"radio","name":`question${index}mediaType`,"value":"image"},domProps:{"checked":_vm._q(question.media_type,"image")},on:{"change":[function($event){return _vm.$set(question, "media_type", "image")},function($event){_vm.fieldUpdate()
										question.media_url = ''}]}}),_c('label',{attrs:{"for":`question${index}mediaTypeImage`}},[_vm._v(_vm._s(_vm.translate('mediaType.image')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.media_type),expression:"question.media_type"}],attrs:{"id":`question${index}mediaTypeAudio`,"type":"radio","name":`question${index}mediaType`,"value":"audio"},domProps:{"checked":_vm._q(question.media_type,"audio")},on:{"change":[function($event){return _vm.$set(question, "media_type", "audio")},function($event){_vm.fieldUpdate()
										question.media_url = ''}]}}),_c('label',{attrs:{"for":`question${index}mediaTypeAudio`}},[_vm._v(_vm._s(_vm.translate('mediaType.audio')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.media_type),expression:"question.media_type"}],attrs:{"id":`question${index}mediaTypeVideo`,"type":"radio","name":`question${index}mediaType`,"value":"video"},domProps:{"checked":_vm._q(question.media_type,"video")},on:{"change":[function($event){return _vm.$set(question, "media_type", "video")},function($event){_vm.fieldUpdate()
										question.media_url = ''}]}}),_c('label',{attrs:{"for":`question${index}mediaTypeVideo`}},[_vm._v(_vm._s(_vm.translate('mediaType.video')))])]):_vm._e(),_c('div',{staticClass:"actions"},[(_vm.playedBy === 0)?_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"delete icon",attrs:{"content":_vm.translate('quiz.deleteQuestion')},on:{"click":function($event){return _vm.deleteQuestion(question.id)}}},[_c('Icon',{attrs:{"name":"trash-2","fill":"red"}})],1):_vm._e(),_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"icon",attrs:{"content":_vm.translate('quiz.previewQuestion')},on:{"click":function($event){return _vm.previewQuestion(index)}}},[_c('Icon',{attrs:{"name":"eye","fill":"gray"}})],1)])]),_c('div',{staticClass:"row auto"},[(['image', 'video'].includes(question.media_type) && question.media_url)?_c('div',{staticClass:"column no-grow"},[(question.media_type === 'image')?_c('div',{staticClass:"image-preview"},[_c('ImageComponent',{attrs:{"id":`question${index}image-preview`,"height":118,"width":222,"src":question.media_url,"overlay":true,"alt":"Image Preview"}})],1):_vm._e(),(question.media_type === 'video')?_c('div',{staticClass:"video-preview"},[_c('VideoThumbnail',{attrs:{"src":question.media_url}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"column"},[_c('div',{staticClass:"form-input",class:{
										error: _vm.error[`question${index}`],
									},attrs:{"data-error":_vm.error[`question${index}`]}},[_c('div',{staticClass:"label"},[_c('label',{attrs:{"for":`question${index}`}},[_vm._v(_vm._s(_vm.translate('quiz.question')))]),(!_vm.readonly)?_c('p',{directives:[{name:"tippy",rawName:"v-tippy"}],class:[
												'char-count',
												{
													'text-red':
														(question.question ? question.question.length : 0) >
														_vm.characterLimits.questionTitle,
												},
											],attrs:{"content":_vm.translate('quiz.maxCharacterLength'),"tabindex":"-1"}},[_vm._v(" "+_vm._s(question.question ? question.question.length : 0)+"/"+_vm._s(_vm.characterLimits.questionTitle)+" ")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.question),expression:"question.question"}],class:[
											`state-${
												_vm.state[
													(`question${index}`,
													{
														readonly: _vm.playedBy > 0,
													})
												]
											}`,
										],attrs:{"id":`question${index}`,"type":"text","data-maxlength":_vm.characterLimits.questionTitle,"placeholder":_vm.translate('quiz.question'),"readonly":_vm.readonly},domProps:{"value":(question.question)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(question, "question", $event.target.value)},function($event){return _vm.fieldUpdate($event)}]}}),_c('InputState',{attrs:{"state":_vm.state[`question${index}`],"show":!!_vm.state[`question${index}`]}})],1),(question.media_type === 'image')?_c('div',{staticClass:"form-input image",class:{
										error: _vm.error[`question${index}image`],
									},attrs:{"data-error":_vm.error[`question${index}image`]}},[_c('div',{staticClass:"label"},[_c('label',{attrs:{"for":`question${index}image`}},[_vm._v(_vm._s(_vm.translate('quiz.imageUrl')))]),_c('p',{staticClass:"notice"},[_vm._v(" "+_vm._s(_vm.translate('quiz.imageUrl.notice'))+" ")])]),_c('div',{staticClass:"input-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.media_url),expression:"question.media_url"}],class:[
												`state-${
													_vm.state[
														(`question${index}image`,
														{
															readonly: _vm.playedBy > 0,
														})
													]
												}`,
											],style:({
												paddingRight: `${230}px`,
											}),attrs:{"id":`question${index}image`,"type":"text","placeholder":_vm.translate('quiz.imageUrl.placeholder'),"readonly":_vm.readonly},domProps:{"value":(question.media_url)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(question, "media_url", $event.target.value)},function($event){return _vm.fieldUpdate($event)}]}}),_c('InputState',{style:({
												right: '200px',
											}),attrs:{"state":_vm.state[`question${index}image`],"show":!!_vm.state[`question${index}image`]}}),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"small icon-left",on:{"click":function($event){return _vm.selectImage({
														model: question,
														id: `question${index}image`,
													})}}},[_c('Icon',{attrs:{"name":"image","fill":"white","width":"14"}}),_c('span',[_vm._v(_vm._s(_vm.translate('quiz.chooseImage')))])],1),_c('UploadInput',{attrs:{"id":`image-upload-${index}`,"ref-id":`question${index}image`,"model":question,"bucket":"quiz-images","classes":"small icon-left","icon":"cloud-upload-outline","text":_vm.translate('quiz.uploadImage')},on:{"uploaded":_vm.handleUpload}})],1)],1)]):_vm._e(),(question.media_type === 'video')?_c('div',{staticClass:"form-input video",class:{
										error: _vm.error[`question${index}video`],
									},attrs:{"data-error":_vm.error[`question${index}video`]}},[_c('div',{staticClass:"label"},[_c('label',{attrs:{"for":`question${index}video`}},[_vm._v(_vm._s(_vm.translate('quiz.videoUrl')))]),_c('p',{staticClass:"notice"},[_vm._v(" "+_vm._s(_vm.translate('quiz.videoUrl.notice'))+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.media_url),expression:"question.media_url"}],class:[
											`state-${
												_vm.state[
													(`question${index}video`,
													{
														readonly: _vm.playedBy > 0,
													})
												]
											}`,
										],attrs:{"id":`question${index}video`,"type":"text","placeholder":_vm.translate('quiz.videoUrl.placeholder'),"readonly":_vm.readonly},domProps:{"value":(question.media_url)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(question, "media_url", $event.target.value)},function($event){return _vm.fieldUpdate($event)}]}}),_c('InputState',{attrs:{"state":_vm.state[`question${index}video`],"show":!!_vm.state[`question${index}video`]}})],1):_vm._e()])]),(question.media_type === 'audio')?_c('div',{staticClass:"form-input audio",class:{
								error: _vm.error[`question${index}audio`],
							},attrs:{"data-error":_vm.error[`question${index}audio`]}},[_c('div',{staticClass:"label"},[_c('label',{attrs:{"for":`question${index}audio`}},[_vm._v(_vm._s(_vm.translate('quiz.audioUrl')))]),_c('p',{staticClass:"notice"},[_vm._v(" "+_vm._s(_vm.translate('quiz.audioUrl.notice'))+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.media_url),expression:"question.media_url"}],class:[
									`state-${
										_vm.state[
											(`question${index}audio`,
											{
												readonly: _vm.playedBy > 0,
											})
										]
									}`,
								],attrs:{"id":`question${index}audio`,"type":"text","placeholder":_vm.translate('quiz.audioUrl.placeholder'),"readonly":_vm.readonly},domProps:{"value":(question.media_url)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(question, "media_url", $event.target.value)},function($event){return _vm.fieldUpdate($event)}]}}),_c('InputState',{attrs:{"state":_vm.state[`question${index}audio`],"show":!!_vm.state[`question${index}audio`]}})],1):_vm._e(),_c('div',{staticClass:"row auto",staticStyle:{"margin-top":"10px"}},[_c('transition',{attrs:{"name":"fade"}},[(question.media_type === 'audio' && question.media_url)?_c('div',{staticClass:"audio-preview column"},[_c('Audio',{attrs:{"url":question.media_url}})],1):_vm._e()])],1),_c('div',{staticClass:"row three"},_vm._l((question.options),function(answer,i){return _c('div',{key:i,staticClass:"form-input column",class:{
									error: _vm.error[`question${index}answer${i}`],
								},attrs:{"data-error":_vm.error[`question${index}answer${i}`]}},[_c('div',{staticClass:"label"},[_c('label',{attrs:{"for":`question${index}answer${i}`}},[_vm._v(_vm._s(i === 0 ? _vm.translate('quiz.correctAnswer') : _vm.translate('quiz.wrongAnswer')))]),(!_vm.readonly)?_c('p',{directives:[{name:"tippy",rawName:"v-tippy"}],class:[
											'char-count',
											{
												'text-red': question.options[i].length > _vm.characterLimits.questionAnswer,
											},
										],attrs:{"content":_vm.translate('quiz.maxCharacterLength'),"tabindex":"-1"}},[_vm._v(" "+_vm._s(question.options[i].length)+"/"+_vm._s(_vm.characterLimits.questionAnswer)+" ")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.options[i]),expression:"question.options[i]"}],class:[
										`state-${
											_vm.state[
												(`question${index}answer${i}`,
												{
													readonly: _vm.playedBy > 0,
												})
											]
										}`,
									],attrs:{"id":`question${index}answer${i}`,"type":"text","data-maxlength":_vm.characterLimits.questionAnswer,"placeholder":i === 0 ? _vm.translate('quiz.correctAnswer') : _vm.translate('quiz.wrongAnswer'),"readonly":_vm.readonly},domProps:{"value":(question.options[i])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(question.options, i, $event.target.value)},function($event){return _vm.fieldUpdate($event, index)}]}}),_c('InputState',{attrs:{"state":_vm.state[`question${index}answer${i}`],"show":!!_vm.state[`question${index}answer${i}`]}})],1)}),0)])}),0)],1)],1),_c('Transition',{attrs:{"name":"fade","appear":""}},[(_vm.questions.length < _vm.questionsLimit && !_vm.readonly)?_c('button',{staticClass:"add-question",style:({
					transitionDelay: `${1 + _vm.questions.length * 0.1}s`,
				}),on:{"click":_vm.addQuestion}},[_c('Icon',{attrs:{"name":"plus-square","fill":"white"}}),_vm._v(" "+_vm._s(_vm.translate('quiz.addQuestion'))+" ")],1):_vm._e()]),_c('Transition',{attrs:{"name":"fade","appear":""}},[(!_vm.readonly && !_vm.quiz.public)?_c('div',{staticClass:"delete-section",style:({
					transitionDelay: `${1.1 + _vm.questions.length * 0.1}s`,
				})},[_c('div',{staticClass:"inner"},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.translate('quiz.deleteQuiz.label')))]),_c('p',[_vm._v(" "+_vm._s(_vm.translate('quiz.deleteQuiz.description'))+" ")])]),_c('button',{staticClass:"red",on:{"click":_vm.deleteQuiz}},[_vm._v(" "+_vm._s(_vm.translate('delete'))+" ")])])]):_vm._e()])],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[_c('PreviewQuestion',{directives:[{name:"show",rawName:"v-show",value:(_vm.preview),expression:"preview"}],attrs:{"question":_vm.preview},on:{"close":function($event){_vm.preview = null}}})],1),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.previewStartScreen),expression:"previewStartScreen"}],staticClass:"start-screen-preview"},[(_vm.previewStartScreen)?_c('StartScreen',{attrs:{"quiz":_vm.previewStartScreen,"preview":true}}):_vm._e(),_c('button',{staticClass:"icon-left",on:{"click":function($event){_vm.previewStartScreen = null
					_vm.toggleNoScroll()}}},[_c('Icon',{attrs:{"name":"close","width":"20","fill":"white"}}),_vm._v(_vm._s(_vm.translate('close'))+" ")],1)],1)]),(_vm.imageSelect)?_c('ImageSelector',{attrs:{"model":_vm.imageSelect},on:{"close":_vm.selectImage}}):_vm._e(),(!_vm.loading && (_vm.notCreatedByMe || _vm.quizDoesNotExist))?_c('div',{staticClass:"loading-error"},[(_vm.notCreatedByMe)?_c('div',[(_vm.quiz)?_c('div',{staticClass:"quiz-details"},[_c('h3',[_vm._v("'"+_vm._s(_vm.quiz.title)+"'")]),_c('p',[_vm._v(" "+_vm._s(_vm.translate('madeBy'))+" "),_c('User',{attrs:{"user":_vm.quiz.createdBy,"clickable":true}})],1)]):_vm._e(),_c('div',{staticClass:"alert red"},[_c('Icon',{attrs:{"name":"alert-triangle"}}),_c('p',[_vm._v(" "+_vm._s(_vm.translate('quiz.notice.notMadeByYou'))+" ")])],1),_c('MyQuizzes')],1):_vm._e(),(_vm.quizDoesNotExist)?_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.translate('quiz.404'))+" ")]),_c('div',{staticClass:"alert red"},[_c('Icon',{attrs:{"name":"alert-triangle"}}),_c('p',[_vm._v(" "+_vm._s(_vm.translate('quiz.404.first'))+" \""),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.$route.params.id))]),_vm._v("\". "+_vm._s(_vm.translate('quiz.404.second'))+" ")])],1),_c('MyQuizzes')],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }